<!-- 销售详情 -->

<template>
  <div class="saleDetail">
    <back />
    <div class="globle_border" v-loading="loading">
      <!-- echart -->
      <div class="echart" v-show="pieChartData.length > 0">
        <div class="echarts" id="main" style="width: 1600px; height: 400px;"></div>
      </div>
      <el-empty v-show="pieChartData.length == 0" description="暂无图表数据"></el-empty>

      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="shopName" placeholder="搜索商超名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="400">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopCode" label="商超编号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inventoryCount" label="库存数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="sellAmount" label="销售数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inventoryAmount"
            label="库存金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="" label="销售金额占比">
            <template slot-scope="scope">
              <div>{{ scope.row.inventoryProportion + '%' }}</div>
            </template>
          </el-table-column>

        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination class="pagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
    </el-pagination>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      loading: false,
      shopName: '', // 搜索商超名称
      productId: '',//商品id
      // 表格数据
      tableData: [],
      // 饼图数据
      pieChartData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalCount: 0, // 总记录数（需要从后端获取）

      // echart
      myChart: null,
    }
  },
  created() {
    this.productId = this.$route.query.productId
    this.getRequest();
  },
  mounted() {
    // 注意调用顺序，先初始化echarts才给echarts赋值
    this.initEcharts()
    this.setEchartsOption()
  },

  methods: {
    // 获取列表
    getRequest() {
      this.loading = true;
      let params = {
        shopName: this.shopName || null, // 搜索商超名称
        productId: this.productId, // 搜索商品id
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.productAmountViewList, { params })
        .then((res) => {
          const { code, result } = res.data
          if (code == 100) {
            this.tableData = result.list;
            this.totalCount = result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getRequest();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getRequest();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getRequest();
    },

    // 初始化echarts
    initEcharts() {
      var chartDom = document.getElementById('main');
      // 将创建的echarts示例放到vue的data中，这样在这个界面就想到于全局配置了
      this.myChart = echarts.init(chartDom);
    },
    // 配置echarts的option，展示数据图表
    setEchartsOption() {
      const url = `${this.$api.productAmountViewPieChart}/${this.productId}`
      this.$axios.get(url).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.pieChartData = result
          let resultList = result.map(e => {
            return {
              ...e,
              value: e.inventoryAmount,//占比
              name: e.shopName,
            }
          })
          var option = {
            tooltip: { // 全局设置提示框
              trigger: 'item', // 触发类型，默认数据触发，可选为：'item'、'axis'  
              // formatter: "{a} <br/>{b}: {c} ({d}%)" // 内容格式器，{a}（系列名称），{b}（数据名），{c}（数据值），{d}（百分比）  
            },
            legend: {
              right: 200,
              top: 20,
              orient: 'vertical',
            },
            series: [
              {
                type: 'pie',
                radius: '80%',
                data: resultList,
                color: ['#f76848', '#74bdcf', '#7db7f7', '#e7bcf3', '#ffa500', '#4dd0e1', '#ff7f50', '#c0c0c0', '#800080', '#ffd700', '#228b22', '#ff4500'],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                // 提示框设置 会覆盖全局设置
                tooltip: {
                  trigger: 'item', // 触发类型，数据触发  
                  formatter: function (params) {
                    return [
                      '<span style="font-weight: bold;">' + params.name + '</span>', // 名称字体加粗
                      '<br/>库存数量：' + params.data.inventoryCount,
                      '<br/>库存金额：' + params.value.toFixed(2) + '元',
                      '<br/>库存金额占比：' + params.data.inventoryProportion.toFixed(2) + '%'
                    ].join('');
                  },
                }
              }
            ]
          };
          option && this.myChart.setOption(option);
        }
      })
        .catch(() => {
        });


    },
  }

}



</script>

<style scoped lang="scss">
.saleDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }

  .pagination {
    padding-bottom: 30px;
  }
}
</style>
